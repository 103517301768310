import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Link from '../components/Link';
import Logo from '../components/Logo';
import Image from '../components/Image';
import SEO from '../components/SEO';
import DemoForm from '../components/DemoForm';
import CatAuthor from '../components/CatAuthor';
import CloseX from '../assets/images/close-x.inline.svg';

import styled from 'styled-components';
import tw from 'twin.macro';
const Page = styled.div`
  ${tw`flex flex-col`}
  @media screen and (min-width: 640px) and (min-height:1100px) {
    max-height: 1100px;
    height: 100vh;
  }
  @media screen and (min-width: 640px) and (max-height: 1099px) {
    ${tw`min-h-screen`}
  }
`;

export default (props) => {
  if (!props?.data?.seo) return null;

  const { seo } = props.data.wpPage;
  const { heading, content, logos, demoConfirmation } = props.data.wpPage.demoPage;
  const [isSent, setIsSent] = useState(false);

  const correctLogos = !isSent ? logos.logos : demoConfirmation.logos;

  return (
    <Page className={`${!isSent ? 'bg-white' : 'bg-black text-white'}`}>
      <SEO seo={seo} />
      <section className="relative md:py-10 py-8">
        <CloseX onClick={(e) => typeof window != 'undefined' && window.history.go(-1)} className="absolute top-0 right-0 w-4 m-5 text-black hover:text-purple transition-color duration-150 ease-in-out cursor-pointer" style={{ width: '0.85rem' }} />
        <div className="container flex flex-col justify-center items-center">
          <Link to="/">
            <Logo isLight={isSent} />
          </Link>
        </div>
      </section>
      <section className="pt-10 pb-20 text-center flex-auto flex flex-col items-center justify-center">
        <div className="container">
          <header className="mb-10">
            <h1 className="text-58px mb-3">{!isSent ? heading : demoConfirmation.heading}</h1>
            <p className="text-20px font-medium">{!isSent ? content : demoConfirmation.content}</p>
          </header>

          {!isSent && <DemoForm isSent={isSent} setIsSent={setIsSent} />}

          {isSent && (
            <div style={{ maxWidth: '961px' }} className="mx-auto pt-4">
              <div className="grid md:grid-cols-2 gap-4">
                {demoConfirmation.posts.map(({ uri, id, title, categories }) => {
                  const category = categories.nodes[0]?.name || false;
                  return (
                    <Link key={`dark-${id}`} to={uri} className="flex flex-col items-start bg-white text-black p-8 text-left justify-between">
                      <div>
                        <CatAuthor category={category} />
                        <h4 className="text-22px font-semibold my-6">{title}</h4>
                      </div>

                      <button className="text-link text-link--black">Read more</button>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="logos">
        <div className="container">
          <h4 className="text-20px font-semibold text-center">{logos.heading}</h4>

          <div className="mt-8 mb-16 grid md:grid-cols-4 grid-cols-2 row-gap-8 col-gap-12 max-w-2xl items-center justify-center mx-auto">
            {correctLogos.map((logo, i) => (
              <div key={`demo-logo${i}`} className="w-full h-full flex flex-col items-center justify-center">
                <Image data={logo.logo} className=" object-contain max-w-full max-h-full" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Page>
  );
};

export const query = graphql`
  query Demo {
    wpPage(slug: { eq: "demo" }) {
      id
      title
      slug
      ...SEO
      demoPage {
        content
        heading
        logos {
          heading
          logos {
            logo {
              ...Image
            }
          }
        }
        demoConfirmation {
          heading
          content
          posts {
            ... on WpPost {
              ...Post
            }
          }
          logos {
            logo {
              ...Image
            }
          }
        }
      }
    }
  }
`;
