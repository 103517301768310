import React from 'react';
import { useForm } from 'react-hook-form';
import submitForm from '../utils/submitForm';

export default ({ isSent, setIsSent }) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (fields) => {
    const { status } = await submitForm({
      formId: '0886bbfb-abfc-4921-87fa-2f9b8cdb750d',
      fields,
    });

    if (status === 200) {
      setIsSent(true);
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`mx-auto ${isSent ? 'pointer-events-none select-none' : ''}`} style={{ maxWidth: '786px' }}>
      <div className="grid md:grid-cols-2 gap-4">
        <label className={`border-2 block ${errors.email && 'is-invalid'}`}>
          <input type="email" name="email" aria-label="Email" ref={register({ required: true })} placeholder="Your work email" />
        </label>
        <label className={`border-2 block ${errors.name && 'is-invalid'}`}>
          <input type="text" name="name" aria-label="Name" ref={register({ required: true })} placeholder="Your name" />
        </label>
        <label className={`border-2 block ${errors.phone && 'is-invalid'}`}>
          <input type="text" name="phone" aria-label="Phone" ref={register} placeholder="Your number" />
        </label>
        <label className={`border-2 block ${errors.company && 'is-invalid'}`}>
          <input type="text" name="company" aria-label="Company" ref={register} placeholder="Your company" />
        </label>
      </div>
      <label className="text-14px flex items-center justify-center py-6">
        <input type="checkbox" name="accept" ref={register({ required: true })} className="mr-3" />
        <div className={`${errors.accept && 'is-invalid text-red'}`}>
          I accept Programmai’s{' '}
          <a href="/privacy-policy/" className="underline">
            Privacy Policy
          </a>
        </div>
      </label>
      <button type="submit" className="button button--h57 button--black button--waves">
        Book a demo
      </button>
    </form>
  );
};
